import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import {
    IntroToInvesting,
    IntroToInvestingProps
} from 'common/components/templates/IntroToInvesting'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface IntroductionInvestmentsPageProps {
    pageContext: any
}

export const IntroductionInvestmentsPage: React.FC<IntroductionInvestmentsPageProps> = ({
    pageContext
}) => {
    return <IntroToInvesting {...getIntroductionInvestmentsData(pageContext)} />
}

export default IntroductionInvestmentsPage

export const getIntroductionInvestmentsData = (
    data: any
): IntroToInvestingProps => ({
    layout: getLayoutData(data),
    alerts: {
        alerts: data.alerts__alerts.map((alert: any) => alert.text)
    },
    jumbotron: getJumbotronData(data),
    flatcards: {
        cards: data.flatcards__cards.map((card: any) => ({
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            },
            icon: card.icon,
            text: card.text,
            title: card.title
        }))
    },
    notices: data.notice.map((notice: any) => ({
        title: notice.title,
        text: notice.text,
        subTitle: notice.subtitle,
        callToAction: {
            label: notice.call_to_action__label,
            [`${notice.call_to_action__iconlocation}Icon`]: notice.call_to_action__icon,
            text: notice.call_to_action__text,
            title: notice.call_to_action__title,
            url: notice.call_to_action__url,
            variant: notice.call_to_action__variant
        }
    })),
    productList: {
        heading: data.heading,
        text: data.text,
        title: data.title,
        products: (data.products || []).map((product: any) => ({
            icon: product.productlist__icon,
            title: product.productlist__subtitle,
            subtitle: product.jumbotron__title,
            text: product.productlist__summary,
            callToAction: {
                label: product.card_cta_0396ba7__cta_label,
                variant: product.card_cta_0396ba7__cta_variant,
                url: product.card_cta_0396ba7__cta_url
            }
        }))
    }
})
