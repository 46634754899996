import { Box } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React from 'react'
import {
    getOccupationClass,
    getDateOfBith,
} from 'common/components/organisms/Calculator/config/incomeProtection'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { IncomeProtectionFormType } from '../../../../../../utils/interfaces'
export interface YourDetailsCardProps {
    income: number
    amountToProtect: number
}
export const YourDetailsCard: React.FC<YourDetailsCardProps> = ({
    income,
    amountToProtect,
}) => {
    const { state } = useCalculatorMachine()
    const occupation = getOccupationClass(
        (state.context.formValues as IncomeProtectionFormType).occupation
    )
    const { day, month, year } = getDateOfBith(
        (state.context.formValues as IncomeProtectionFormType).dateOfBirth
    )
    return (
        <>
            <Box pt={'12px'}>
                <Card px={'36px'}>
                    <Text variant={'title-xs'}>Your details</Text>
                    <Box mt={'16px'}>
                        <Text variant={'label-md'}>Salary/income:</Text>
                        <Text
                            variant={'label-md'}
                            color={'var(--Black, #0C1E68)'}
                        >
                            {`€${income.toLocaleString()}`}
                        </Text>
                    </Box>
                    <Box mt={'16px'}>
                        <Text variant={'label-md'}>Self employed:</Text>
                        <Text
                            variant={'label-md'}
                            color={'var(--Black, #0C1E68)'}
                        >
                            {(
                                state.context
                                    .formValues as IncomeProtectionFormType
                            )?.employmentStatus === 'Y'
                                ? 'Yes'
                                : 'No'}
                        </Text>
                    </Box>
                    <Box mt={'16px'}>
                        <Text variant={'label-md'}>
                            Amount of income to protect:
                        </Text>
                        <Text
                            variant={'label-md'}
                            color={'var(--Black, #0C1E68)'}
                        >
                            {`${amountToProtect.toLocaleString()}%`}
                        </Text>
                    </Box>
                    <Box mt={'16px'}>
                        <Text variant={'label-md'}>Retirement age:</Text>
                        <Text
                            variant={'label-md'}
                            color={'var(--Black, #0C1E68)'}
                        >
                            65
                        </Text>
                    </Box>
                    <Box mt={'16px'}>
                        <Text variant={'label-md'}>Date of birth:</Text>
                        <Text
                            variant={'label-md'}
                            color={'var(--Black, #0C1E68)'}
                        >
                            {`${day}/${month}/${year}`}
                        </Text>
                    </Box>
                    <Box mt={'16px'}>
                        <Text variant={'label-md'}>Occupation:</Text>
                        <Text
                            variant={'label-md'}
                            color={'var(--Black, #0C1E68)'}
                        >
                            {occupation?.label}
                        </Text>
                    </Box>
                    <Box mt={'16px'}>
                        <Text variant={'label-md'}>Smoker:</Text>
                        <Text
                            variant={'label-md'}
                            color={'var(--Black, #0C1E68)'}
                        >
                            {(
                                state.context
                                    .formValues as IncomeProtectionFormType
                            )?.isSmoker === 'Y'
                                ? 'Yes'
                                : 'No'}
                        </Text>
                    </Box>
                    <Box mt={'16px'}>
                        <Text variant={'label-md'}>Deferred period:</Text>
                        <Text
                            variant={'label-md'}
                            color={'var(--Black, #0C1E68)'}
                        >
                            26 weeks
                        </Text>
                    </Box>
                    <Box mt={'16px'}>
                        <Text variant={'label-md'}>
                            Increase with inflation:
                        </Text>
                        <Text
                            variant={'label-md'}
                            color={'var(--Black, #0C1E68)'}
                        >
                            Yes
                        </Text>
                    </Box>
                </Card>
            </Box>
        </>
    )
}
