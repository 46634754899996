import {
    getJumbotronData,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { CampaignLandingMicro } from 'common/components/templates/CampaignLanding/CampaignLandingMicro'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface CampaignLandingMicroPageProps {
    pageContext: any
}

export const CampaignLandingMicroPage: React.FC<CampaignLandingMicroPageProps> = ({
    pageContext
}) => {
    return <CampaignLandingMicro {...getCampaignLandingData(pageContext)} />
}

export default CampaignLandingMicroPage
export const getCampaignLandingData = (data: any) => ({
    layout: {
        ...getLayoutData(data),
        jumbotronVariant: 'campaign' as JumbotronProps['variant']
    },
    jumbotron: {
        ...getJumbotronData(data),
        image: data.campaign_jumbotron_image,
        text: data.campaign_jumbotron_text,
        title: data.campaign_jumbotron_title,
        variant: 'campaign' as JumbotronProps['variant']
    },
    video: {
        height: data.video__height,
        width: data.video__width,
        url: data.video__url,
        source: data.video__video,
        title: data.video__title,
        text: data.video__text
    },
    threeCards: {
        benefits: (data.threecards__cards || []).map((card: any) => ({
            ...card,
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            }
        })),
        subtitle: data.threecards__subtitle,
        title: data.threecards__title,
        ctaLabel: data.threecards__cta_label,
        ctaUrl: data.threecards__cta_url,
        analyticsId: data.threecards__ctaanalyticsid
    },
    campaignFeature: {
        title: data.campaign_feature_title,
        text: data.campaign_feature_text,
        image: data.campaign_feature_image,
        callToAction: {
            analyticsId: data.call_to_action__analytics_id,
            label: data.call_to_action__label,
            [`${data.call_to_action__iconlocation}Icon`]: data.call_to_action__icon,
            text: data.call_to_action__text,
            title: data.call_to_action__title,
            url: data.call_to_action__url,
            variant: data.call_to_action__variant
        }
    },
    alerts: {
        alerts: data.alerts__alerts?.map((alert: any) => alert.text)
    }
})
