import { Box, Container } from '@chakra-ui/react'
import { InvestmentCalculator } from '@irishlifedigitalhub/ds.organisms.investment-calculator'
import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Alerts, AlertsProps } from 'common/components/organisms/Alerts'
import {
    Flatcards,
    FlatcardsProps,
} from 'common/components/organisms/Flatcards'
import {
    Jumbotron,
    JumbotronProps,
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { Notice, NoticeProps } from 'common/components/organisms/Notice'
import {
    ProductList,
    ProductListProps,
} from 'common/components/organisms/ProductList'
import { useBrowser } from 'common/hooks/useBrowser'
import React from 'react'
import TagManager from 'react-gtm-module'

export interface IntroToInvestingProps {
    alerts: AlertsProps
    jumbotron: JumbotronProps
    flatcards: FlatcardsProps
    notices: NoticeProps[]
    layout: LayoutProps
    productList: ProductListProps
}

export const IntroToInvesting: React.FC<IntroToInvestingProps> = ({
    jumbotron,
    alerts,
    flatcards,
    notices = [],
    layout,
    productList,
}) => {
    const isBrowser = useBrowser()
    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <ProductList {...productList} />
            <Box backgroundColor='#fff'>
                <Flatcards {...flatcards} />
            </Box>
            <Box backgroundColor='#fff'>
                <Container maxW='6xl' px={18}>
                    <InvestmentCalculator
                        onBook={() => {
                            const el = isBrowser
                                ? document.getElementById('advisor-booking')
                                : null
                            if (el !== null) {
                                el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start',
                                })
                            }
                        }}
                        onCalculate={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'secondaryButtonClick',
                                    clickID:
                                        'secondary-cta-button-calculate-investment-growth-calculator',
                                },
                            })
                        }}
                    />
                </Container>
                <Spacer />
            </Box>

            <Spacer />
            {notices.length ? (
                <Wrapper>
                    {notices.map((notice, index) => (
                        <Notice {...notice} key={index} />
                    ))}
                    <Spacer />
                </Wrapper>
            ) : null}
            <Alerts {...alerts} />
        </Layout>
    )
}
