import { Fund, SfdrFund } from 'common/components/organisms/FundCentre/SFDR'
import { API_TOKEN, API_URL } from 'core/config/env'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useBrowser } from './useBrowser'

const apiUrl = `${API_URL}/myonlineservices`

export const useKidsDocuments = () => {
    const [documents, setDocuments] = useState([])
    const browser = useBrowser()
    useEffect(() => {
        const effect = async () => {
            if (browser) {
                fetch(apiUrl + '/KidQueryApi/List', {
                    headers: {
                        'Auth-Token': API_TOKEN,
                        TimeStamp: moment(new Date()).format(
                            'DD/MM/YYYY HH:mm:ss'
                        )
                    },
                    method: 'post'
                })
                    .then(response => {
                        if (!response.ok)
                            throw new Error('Failed to fetch document list')
                        return response.json()
                    })
                    .then(data => {
                        setDocuments(data)
                    })
                    .catch(e => console.log(e))
            }
        }
        effect()
    }, [browser])
    return documents
}

export const useSfdrProductsAndFunds = () => {
    const [products, setProducts] = useState([])
    const browser = useBrowser()

    useEffect(() => {
        const effect = () => {
            if (browser) {
                fetch(apiUrl + '/SfdrApi/ProductFundList', {
                    headers: {
                        'Auth-Token': API_TOKEN,
                        TimeStamp: moment(new Date()).format(
                            'DD/MM/YYYY HH:mm:ss'
                        )
                    },
                    method: 'post'
                })
                    .then(response => {
                        if (!response.ok)
                            throw new Error('Failed to fetch funds')
                        return response.json()
                    })
                    .then(data => {
                        setProducts(data?.sfdrProductsAndFunds)
                    })
            }
        }
        effect()
    }, [browser])
    return products
}

export const useSfdrDocuments = (productsAndFunds: Fund[]) => {
    const [products, setProducts] = useState<Fund[]>()
    setProducts(productsAndFunds)
    const [documents, setDocuments] = useState<SfdrFund[]>()
    useEffect(() => {
        const effect = () => {
            const fundList = products
                ? products.map(product => {
                      return product.sfdrFunds
                  })
                : []
            if (_.isNull(fundList) || _.isUndefined(fundList)) {
                return []
            } else {
                setDocuments(fundList.flat().sort())
            }
        }
        effect()
    }, [products])
    return documents
}

export const getPerformanceScenarios = async (
    body: any,
    year: string,
    month: string
) => {
    const response = await fetch(
        `${apiUrl}/KidQueryApi/GetPerformanceScenarios`,
        {
            headers: {
                'Auth-Token': API_TOKEN,
                TimeStamp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify({
                systemId: 'OLS',
                userId: 'y364',
                fundCode: body.Fund,
                premiumType: body.PremiumType,
                distributionCode: body.SellerCode,
                productCode: body.ProductCode,
                kidMonth: month,
                kidYear: year,
                systemReference: ''
            })
        }
    )

    if (!response.ok) {
        const message = `Error fetching performance scenarios: ${response.status}`
        throw new Error(message)
    }

    const scenarios = await response.json()
    return scenarios
}
