import { Video } from 'common/components/atoms/Video'
import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { AlertsProps } from 'common/components/organisms/Alerts'
import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import { CampaignFeatureProps } from 'common/components/organisms/CampaignFeature'
import {
    CampaignJumbotron,
    CampaignJumbotronProps
} from 'common/components/organisms/CampaignJumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { CampaignSection } from './CampaignSection'

import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface CampaignLandingMicroProps {
    layout: LayoutProps
    jumbotron: CampaignJumbotronProps
    alerts: AlertsProps
    video: any
    campaignFeature: CampaignFeatureProps
    threeCards: BenefitsProps
}

export const CampaignLandingMicro: React.FC<CampaignLandingMicroProps> = ({
    layout,
    jumbotron,
    threeCards,
    alerts,
    video,
    campaignFeature
}) => {
    const desktop = useDesktop()
    return (
        <Layout {...layout}>
            <CampaignJumbotron {...jumbotron} />(
            <Wrapper style={{ background: '#f7f7fb' }}>
                {threeCards.title && (
                    <>
                        <Benefits {...threeCards} title={threeCards?.title} />
                        <Spacer variant={desktop ? 'lg' : 'md'} />
                    </>
                )}

                <Video {...video} />
            </Wrapper>
            <CampaignSection
                campaignFeature={campaignFeature}
                alerts={alerts}
            />
            <Spacer />
        </Layout>
    )
}
