import { Stack, useBreakpointValue } from '@chakra-ui/react'
import { Text as DSText } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Box, Grid } from '@material-ui/core'
import { RichText } from 'common/components/molecules/RichText'
import { Spacer } from 'common/components/molecules/Spacer'
// import { Text } from 'common/components/molecules/Text'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { Hero } from '../Hero'
import {
    JumbotronCallToAction,
    JumbotronCallToActionProps,
} from './JumbotronCallToAction'
import { JumbotronContact, JumbotronContactProps } from './JumbotronContact'
import { JumbotronWithImage } from './JumbotronWithImage'

export interface JumbotronProps {
    callToAction?: JumbotronCallToActionProps
    secondaryCallToAction?: JumbotronCallToActionProps
    subtitle?: string
    text: string
    authors?: string
    title: string
    image?: string
    logo?: string
    variant?:
        | 'default'
        | 'campaign'
        | 'image'
        | 'flat'
        | 'hero'
        | 'slim'
        | 'home'
        | 'background'
    contact?: JumbotronContactProps
}

export const Jumbotron: React.FC<JumbotronProps> = ({
    variant = 'default',
    ...props
}) => {
    const {
        callToAction,
        secondaryCallToAction,
        subtitle,
        text,
        title,
        authors,
        contact,
    } = props
    const desktop = useDesktop()
    const secondaryCtaVariant = useBreakpointValue({
        base: 'secondary',
        xl: 'ghost',
    }) as JumbotronCallToActionProps['variant']

    if (variant === 'background') return <Hero {...props} />

    if (variant === 'image' && props.image)
        return <JumbotronWithImage {...props} />
    return (
        <Box bgcolor='#fff' data-testid='Jumbotron' component='section'>
            <Wrapper>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        {/* Output both fields wrapped in an h1 tag for semantics */}
                        <h1>
                            {authors && (
                                <DSText
                                    variant='subtitle-md'
                                    color='monochrome.900'
                                    mb='24px'
                                    fontWeight='normal'
                                >
                                    {authors}
                                </DSText>
                            )}
                            <DSText variant='title-2xl' mb='24px'>
                                {title}
                            </DSText>
                            {subtitle && (
                                <DSText
                                    color='monochrome.800'
                                    variant='title-md'
                                >
                                    {subtitle}
                                </DSText>
                            )}
                        </h1>
                        <JumbotronContact {...contact} />
                        {/* <Text
                            box={{ color: '#766F90', mt: 7 }}
                            html
                            label={text}
                            variant='subtitle1'
                            component='p'
                        /> */}
                        <RichText
                            color='textSecondary'
                            html
                            label={text}
                            variant='body2'
                        ></RichText>
                        <Stack
                            direction={{ base: 'column', xl: 'row' }}
                            spacing={{ base: '12px', xl: '28px' }}
                            mt='36px'
                        >
                            {(callToAction?.label ||
                                callToAction?.jumbotronLabel) && (
                                <JumbotronCallToAction
                                    {...callToAction}
                                    noSpacer
                                />
                            )}
                            {(secondaryCallToAction?.label ||
                                secondaryCallToAction?.jumbotronLabel) && (
                                <JumbotronCallToAction
                                    {...secondaryCallToAction}
                                    variant={secondaryCtaVariant}
                                />
                            )}
                        </Stack>
                        {variant !== 'slim' && (
                            <Spacer variant={desktop ? 'lg' : 'md'} />
                        )}
                    </Grid>
                </Grid>
            </Wrapper>
        </Box>
    )
}
