import { Box, Grid } from '@material-ui/core'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { DownloadLink } from '../Resources/DownloadLink'

export interface DownloadFeatureProps {
    features: {
        title: string
        fileUrl: string
    }[]
}

export interface TestProps {
    title: string
}

export const DownloadFeature: React.FC<DownloadFeatureProps> = ({
    features = []
}) => {
    const desktop = useDesktop()

    return (
        <Grid item xs={12} md={6}>
            <Box
                data-testid='DownloadFeature'
                mx={desktop ? 6 : 0}
                my={6}
                display='flex'
                flexDirection='column'
            >
                {features.map((feature, index) => (
                    <DownloadLink
                        key={index}
                        text={feature.title}
                        url={feature.fileUrl}
                    />
                ))}
            </Box>
        </Grid>
    )
}
