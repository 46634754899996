import { GA_ID, GTM_ID } from 'core/config'
import React from 'react'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { Router } from '../Router'

ReactGA.initialize(GA_ID, {
    testMode: process.env.NODE_ENV === 'test'
})

TagManager.initialize({
    gtmId: GTM_ID
})

export interface IAppProps {}

export const App: React.FC<IAppProps> = () => {
    return <Router />
}
