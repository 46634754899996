import { RouteComponentProps, useLocation } from '@reach/router'
import React from 'react'

export interface ScrollToTopProps extends RouteComponentProps {}

export const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
    const location = useLocation()
    React.useEffect(() => window.scrollTo(0, 0), [location.pathname])
    return (children || null) as React.ReactElement
}
