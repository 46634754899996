import React from 'react'
import { Box } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { getStyles } from '../../styles/getStyles'
import { CalculatorInputType } from 'common/components/organisms/Calculator/utils/interfaces'
import { Datepicker } from '@irishlifedigitalhub/ds.molecules.datepicker'

export const DatePicker: React.FC<CalculatorInputType> = ({
    register,
    placeholder,
    label,
    fieldName,
    inputtype,
    control,
    errorMessage,
    setIsTouched,
    isInvalid,
    defaultValue = '',
}) => {
    return (
        <>
            <Controller
                control={control}
                name={fieldName ?? ''}
                defaultValue=''
                rules={{
                    required: errorMessage,
                }}
                render={({ onChange, onBlur, value }) => (
                    <Box sx={getStyles({ isInvalid })}>
                        <Datepicker
                            isInvalid={!!isInvalid}
                            id={fieldName ?? ''}
                            label={label}
                            onChange={onChange}
                        />
                    </Box>
                )}
            />
        </>
    )
}
