import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { FinancialAdvice } from 'common/components/templates/FinancialAdvice'
import { useUrl1 } from 'common/hooks/useUrl1'
import { getContactData } from 'common/utils/getContactData'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface FinancialAdvicePageProps {
    pageContext: any
}

export const FinancialAdvicePage: React.FC<FinancialAdvicePageProps> = ({
    pageContext
}) => {
    const page = useUrl1(1)
    return (
        <FinancialAdvice {...getFinancialAdvicePageData(pageContext, page)} />
    )
}

export default FinancialAdvicePage

export const getFinancialAdvicePageData = (data: any, page: string) => ({
    layout: getLayoutData(data),
    jumbotron: getJumbotronData(data),
    title: data.jumbotron__subtitle,
    featureList: {
        text: data.featurelist__text,
        cta: data.featurelist__cta,
        callToAction: {
            analyticsId: data.featurelist__cta_analyticsid,
            label: data.featurelist__cta_label,
            [`${data.call_to_action__iconlocation}Icon`]: data.featurelist__cta_icon,
            url: data.call_to_action__url,
            variant: data.call_to_action__variant,
            noSpacer: true
        },
        image: data.featurelist__image,
        title: data.featurelist__title,
        bulletsTitle: data.featurelist__bullets_title,
        subtitle: data.featurelist__subtitle,
        features: data.featurelist__features,
        variant: data.featurelist__variant
    },
    content: data.content,
    contact: getContactData(data.contact_form[0])
})
