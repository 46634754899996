import { RouteComponentProps } from '@reach/router'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import SupportCenter from 'common/components/organisms/KnowledgeBase/SupportCenter'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface KnowledgeBaseProps extends RouteComponentProps {
    pageContext: any
}

export const KnowledgeBase: React.FC<KnowledgeBaseProps> = ({
    pageContext,
}) => {
    return (
        <div style={{ backgroundColor: '#F1F3FD' }}>
            <Layout
                {...(pageContext.layout as LayoutProps)}
                breadcrumbs={{
                    breadcrumbs: [{ name: 'Knowlegde Base Embed', url: '/' }],
                }}
            >
                <Wrapper>
                    <SupportCenter />
                </Wrapper>
            </Layout>
        </div>
    )
}
