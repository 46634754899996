import { navigate } from '@reach/router'
import TagManager from 'react-gtm-module'

export function getCampaignFeatureProps(data: Record<string, any>) {
    return {
        imageSrc: data.image,
        title: data.title,
        paragraph: data.text,
        buttonText: data.callToAction.label,
        buttonProps: {
            onClick: () => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'secondaryButtonClick',
                        clickText: data?.callToAction?.label,
                        clickURL: data?.callToAction?.url,
                        clickID: data?.callToAction?.analyticsId
                    }
                })
                navigate(data?.callToAction?.url || '')
            }
        },
        secondaryButtonText: data.secondaryCallToAction?.label,
        secondaryButtonProps: {
            onClick: () => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'secondaryButtonClick',
                        clickText: data?.secondaryCallToAction?.label,
                        clickURL: data?.secondaryCallToAction?.url,
                        clickID: data?.secondaryCallToAction?.analyticsId
                    }
                })
                navigate(data?.secondaryCallToAction?.url || '')
            }
        }
    }
}
