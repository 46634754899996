import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { CardList } from 'common/components/templates/CardList'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface CardListPageProps {
    pageContext: any
}

export const CardListPage: React.FC<CardListPageProps> = ({ pageContext }) => {
    return <CardList {...getCardListPageData(pageContext)} />
}

export default CardListPage

export const getCardListPageData = (data: any) => ({
    layout: getLayoutData(data),
    jumbotron: getJumbotronData(data),
    productList: {
        heading: data.heading,
        title: data.title,
        text: '',
        products: (data.products || []).map((product: any) => ({
            icon: product.icon,
            subtitle: product.productlist__subtitle,
            title: product.title,
            text: product.text,
            callToAction: {
                label: product.card_cta_0396ba7__cta_label,
                variant: product.card_cta_0396ba7__cta_variant,
                url: product.card_cta_0396ba7__cta_url
            }
        }))
    },
    extraDownloads: {
        features: (data.extra_downloads || []).map((extra_download: any) => ({
            title: extra_download.title,
            fileUrl: extra_download.file_url
        }))
    }
})
