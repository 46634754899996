import { ProgressBarStepperStepsType } from './interfaces'

export const stepperSteps: ProgressBarStepperStepsType = {
    Investment: [
        {
            title: 'Starting amount',
        },
        {
            title: 'About you',
        },
        {
            title: 'Calculation',
        },
    ],
    Pensions: [
        {
            title: 'Status',
        },
        {
            title: 'Calculate',
        },
        {
            title: 'About you',
        },
        {
            title: 'Pension',
        },
    ],
    IncomeProtection: [
        {
            title: 'Start',
        },
        {
            title: 'Status',
        },
        {
            title: 'About you',
        },
        {
            title: 'Your Quote',
        },
    ],
}
