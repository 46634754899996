import { FundDocument } from 'common/components/organisms/FundCentre/utils'
import { API_TOKEN, API_URL } from 'core/config/env'
import download from 'downloadjs'
import moment from 'moment'
import qs from 'query-string'
import sanitize from 'sanitize-filename'

const apiUrl = `${API_URL}/myonlineservices`

export interface DocumentDownload {
    [index: string]: string
    premiumType: string
    distributionCode: string
    productCode: string
    fundCode: string
}

export const mapDocumentToDownload = (
    document: FundDocument
): DocumentDownload => ({
    premiumType: document.PremiumType,
    distributionCode: document.SellerCode,
    productCode: document.ProductCode,
    fundCode: document.Fund,
})

export const getFilename = (document: FundDocument): string =>
    sanitize(
        `${document.PublicFundName}-${document.PublicProductName}-${document.PremiumTypeDesc}-${document.SellerCode}.pdf`.replace(
            / /gi,
            '_'
        )
    )

export const downloadKidsDocument = async (document: FundDocument) => {
    const urlParams = qs.stringify(mapDocumentToDownload(document))
    const response = await fetch(
        `${apiUrl}/KidQueryApi/DownloadFile?${urlParams}`,
        {
            headers: {
                'Auth-Token': API_TOKEN,
                TimeStamp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
            },
            method: 'post',
        }
    )
    download(await response.blob(), getFilename(document))
}

export const downloadSFDRDocument = async (fundId: string, docType: number) => {
    const response = await fetch(
        `${apiUrl}/SfdrApi/DownloadDisclosureDocument`,
        {
            headers: {
                TimeStamp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                'Auth-Token': API_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                disclosureDocumentQueries: [
                    {
                        fundCode: fundId,
                        documentType: docType,
                    },
                ],
            }),
            method: 'post',
        }
    )
    const data = response.clone()
    const parsedResponse = await data.json()

    if (parsedResponse.DocumentFormatType === 1) {
        const blob = new Blob([
            new Uint8Array(parsedResponse.File.FileContents).buffer,
        ])
        download(blob, parsedResponse.DocumentName, 'application/pdf')
    } else if (parsedResponse.DocumentFormatType === 0) {
        window.open(parsedResponse.DocumentName, '_blank')
    }
}
