import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'
import { ContactUs, ContactUsProps } from '../components/templates/ContactUs'

export interface ContactFormPageProps {
    pageContext: any
}

export const ContactFormPage: React.FC<ContactFormPageProps> = ({
    pageContext
}) => {
    return <ContactUs {...getContactUsData(pageContext)} />
}

export default ContactFormPage

const getContactUsData = (data: any): ContactUsProps => {
    return {
        layout: getLayoutData(data),
        jumbotron: getJumbotronData(data),

        title: data.title,
        description: data.description,
        sectionHeading: data.section_heading,
        contactCards: (data.contact_cards || []).map((card: any) => ({
            title: card.title,
            icon: card.icon,
            phoneNumber: card.phoneNumber,
            subtitle: card.subtitle,
            text: card.text,
            times: card.times,
            email: card.email,
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            }
        })),
        resources: {
            title: data.resources_title,
            text: data.resources_text,
            downloads: data.resources_downloads,
            subtitle: data.resources_subtitle
        },
    } as ContactUsProps
}
