import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import {
    ProductOneplan,
    ProductOneplanProps
} from 'common/components/templates/ProductOnePlan'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface ProductOneplanPageProps {
    pageContext: any
}

export const ProductOneplanPage: React.FC<ProductOneplanPageProps> = ({
    pageContext
}) => {
    return <ProductOneplan {...getProductOneplanData(pageContext)} />
}

export default ProductOneplanPage

export const getProductOneplanData = (data: any): ProductOneplanProps => ({
    layout: getLayoutData(data),
    benefits: {
        benefits: data.benefits.map((benefit: any) => ({
            callToAction: {
                label: benefit.card_cta_0396ba7__cta_label,
                variant: benefit.card_cta_0396ba7__cta_variant,
                url: benefit.card_cta_0396ba7__cta_url
            },
            icon: benefit.icon,
            text: benefit.text,
            title: benefit.title
        })),
        title: data.benefits_title,
        subtitle: data.benefits_subtitle
    },
    jumbotron: getJumbotronData(data),
    resources: {
        title: data.resources_title,
        text: data.resources_text,
        downloads: data.downloads
    }
})
