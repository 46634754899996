import { Box } from '@chakra-ui/react'
import { Breadcrumb } from '@irishlifedigitalhub/ds.molecules.breadcrumb'
import {
    Jumbotron as HomeJumbotron,
    JumbotronProps,
} from '@irishlifedigitalhub/ds.organisms.jumbotron'
import { navigate } from '@reach/router'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Jumbotron } from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { theme } from 'core'
import React from 'react'
import TagManager from 'react-gtm-module'
import {
    TemplateSectionComponent,
    TemplateSectionType,
} from './templateSectionMapper'

export interface Section {
    type?: TemplateSectionType
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GenericProps {
    codename?: string
    jumbotron: any
    layout: LayoutProps
    sections: Section[]
}

export const Generic: React.FC<GenericProps> = ({
    codename,
    jumbotron,
    layout,
    sections,
}) => {
    const newLayout = {
        ...layout,
        navigation: layout.customNavigationMenu || layout.navigation,
    }
    return (
        <Layout {...newLayout}>
            {jumbotron.variant !== 'home' &&
                codename !== 'knowledge' &&
                !codename?.includes('calculator__') && (
                    <Jumbotron {...jumbotron} />
                )}
            {jumbotron.variant === 'home' &&
                codename !== 'knowledge' &&
                !codename?.includes('calculator__') && (
                    <HomeJumbotron {...getTemplateJumbotron(jumbotron)} />
                )}
            {getSections(sections)}
        </Layout>
    )
}

export const getTemplateJumbotron = (
    jumbotron: any,
    breadcrumbs?: any
): JumbotronProps => {
    return {
        src: jumbotron.image,
        title: jumbotron.title,
        subtitle: jumbotron.subtitle,
        paragraph: jumbotron.text,
        renderBreadcrumb: <></>,

        buttonText: jumbotron.callToAction?.label,
        buttonProps: {
            onClick: () => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'primaryButtonClick',
                        clickText: jumbotron?.callToAction?.label,
                        clickURL: jumbotron?.callToAction?.url,
                        clickID: jumbotron?.callToAction?.analyticsId,
                    },
                })
                navigate(jumbotron?.callToAction?.url || '')
            },
        },
    }
}

export const getBreadCrumbs = (breadcrumbs: any) => {
    const items = breadcrumbs.map((item: any, index: number) => {
        return {
            id: index + 1,
            label: item.name,
            value: item.url,
        }
    })
    const finalBreadCrumbs = [{ id: 0, label: 'Home', value: '/' }, ...items]
    return (
        <Box mt={'22px'}>
            <Breadcrumb items={finalBreadCrumbs} />
        </Box>
    )
}

export const getSections = (sections: any[] = []) => {
    // Helper function to determine if extra space should be displayed
    const displayExtraSpace = (condition: string) =>
        condition === 'Yes' ? 'block' : 'none'

    // Helper function to get background color
    const getBackgroundColor = (
        defaultColor: string | undefined,
        type: string
    ) => {
        switch (type) {
            case 'templatesection___card_grid':
                return '#EBEDF7'
            case 'templatesection___minifooter':
                return '#fff'
            default:
                return defaultColor
        }
    }

    // set of sections that should not be wrapped in extra space
    const shouldNotBeWrapped = new Set([
        'templatesection___trapeziumbanner',
        'templatesection___multicampaignfeature',
        'templatesection___campaign3dslideshow',
    ])

    return sections.map(
        (
            {
                type,
                templatesection_common__spacer,
                templatesection_common__extra_space_above_and_bottom,
                templatesection_common__background_color = 'transparent',
                ...props
            },
            idx
        ) => {
            const bgColor = bgColors[templatesection_common__background_color]
            const Component = TemplateSectionComponent[type]
            const aboveAndBottomBackground = getBackgroundColor(bgColor, type)

            if (!Component) return null

            //This will show only when templatesection_common__extra_space_above_and_bottom is set to 'Yes' in the CMS
            const ExtraSpaceBox = (
                <Box
                    h='100px'
                    bg={aboveAndBottomBackground}
                    display={displayExtraSpace(
                        templatesection_common__extra_space_above_and_bottom
                    )}
                />
            )

            return (
                <Box key={idx}>
                    {shouldNotBeWrapped.has(type) ? (
                        <>
                            {ExtraSpaceBox}
                            <Box id={getSectionId(type)} bg={bgColor}>
                                <Component {...props} />
                            </Box>
                            {ExtraSpaceBox}
                        </>
                    ) : (
                        <Box>
                            {ExtraSpaceBox}
                            <Box h='48px' bg={aboveAndBottomBackground} />
                            <Box id={getSectionId(type)} bg={bgColor}>
                                {Component?.displayName === 'Testimonials' ? (
                                    <Component {...props} />
                                ) : (
                                    <Wrapper>
                                        <Component {...props} />
                                    </Wrapper>
                                )}
                            </Box>
                            <Box h='48px' bg={aboveAndBottomBackground} />
                            {ExtraSpaceBox}
                        </Box>
                    )}
                </Box>
            )
        }
    )
}

const bgColors: { [key: string]: string | undefined } = {
    transparent: undefined,
    white: theme.palette.common.white,
    lightBlue: '#EBEDF7',
    deepBlue: '#4D599E',
}

const getSectionId = (type: string) =>
    type.replace('templatesection___', '').toLowerCase()
