import { Spacer } from 'common/components/molecules/Spacer'
import { Alerts, AlertsProps } from 'common/components/organisms/Alerts'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    ProductList,
    ProductListProps
} from 'common/components/organisms/ProductList'
import React from 'react'

export interface NewToInvestingProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
    productList: ProductListProps
    alerts: AlertsProps
}

export const NewToInvesting: React.FC<NewToInvestingProps> = ({
    jumbotron,
    layout,
    productList,
    alerts
}) => {
    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <ProductList {...productList} singleColumn iconSize='lg' />
            <Spacer />
            <Alerts {...alerts} />
        </Layout>
    )
}
