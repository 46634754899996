import { ThemeProvider } from '@irishlife/ilgroupdesignsystem.theme.theme-provider'
import { MuiThemeProvider } from '@material-ui/core'
import { App } from 'core/components'
import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import { CSSReset } from '../css-reset'
import { theme } from './config'

ReactDOM.render(
    <RecoilRoot>
        <MuiThemeProvider theme={theme}>
            <ThemeProvider resetCSS={false}>
                <CSSReset />
                <App />
            </ThemeProvider>
        </MuiThemeProvider>
    </RecoilRoot>,
    document.getElementById('root')
)
