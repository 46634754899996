import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { IntroductionToPensions } from 'common/components/templates/IntroductionToPensions'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface IntroductionPensionsPageProps {
    pageContext: any
}

export const IntroductionPensionsPage: React.FC<IntroductionPensionsPageProps> = ({
    pageContext
}) => {
    return (
        <IntroductionToPensions {...getIntroductionPensionsData(pageContext)} />
    )
}

export default IntroductionPensionsPage

const getIntroductionPensionsData = (data: any) => ({
    layout: getLayoutData(data),
    benefits: {
        benefits: data.threecards__cards,
        title: data.threecards__title,
        subtitle: data.threecards__subtitle
    },
    button: {
        analyticsId: data.threecards__analytics_id,
        label: data.threecards__cta_label,
        url: data.threecards__cta_url
    },
    featured: {
        title: data.featured__title,
        text: data.featured__text,
        callToAction: {
            [`${data.call_to_action__iconlocation}Icon`]: data.call_to_action__icon,
            text: data.featured__label,
            url: data.featured__url,
            variant: data.featured__variant,
            analyticsId: data.featured__analytics_id
        }
    },
    flatcards: { cards: (data.flatcards__cards.map((card: any) => ({
        callToAction: {
            label: card.card_cta_0396ba7__cta_label,
            variant: card.card_cta_0396ba7__cta_variant,
            url: card.card_cta_0396ba7__cta_url
        },
        text: card.text,
        title: card.title,
        icon: card.icon
    })) )},
    jumbotron: getJumbotronData(data),
    productList: {
        heading: data.productlist_heading,
        text: data.productlist_text,
        title: data.productlist_title,
        products: (data.products_pensions as Array<any>).map(
            (product: any) => ({
                callToAction: {
                    label: product.card_cta_0396ba7__cta_label,
                    variant: product.card_cta_0396ba7__cta_variant,
                    url: product.card_cta_0396ba7__cta_url
                },
                icon: product.productlist__icon,
                title: product.jumbotron__title,
                text: product.productlist__summary
            })
        )
    }
})
