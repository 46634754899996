import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { NewToInvesting } from 'common/components/templates/NewToInvesting'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface NewToInvestingPageProps {
    pageContext: any
}

export const NewToInvestingPage: React.FC<NewToInvestingPageProps> = ({
    pageContext
}) => {
    return <NewToInvesting {...getNewToInvestingData(pageContext)} />
}

export default NewToInvestingPage

export const getNewToInvestingData = (data: any) => ({
    layout: getLayoutData(data),
    jumbotron: getJumbotronData(data),
    productList: {
        heading: data.productlist__heading,
        text: data.productlist__text,
        title: data.productlist__title,
        products: (data.products_newtoinvestment || []).map((product: any) => ({
            icon: product.icon,
            title: product.title,
            text: product.text
        }))
    },
    alerts: {
        alerts: data.alerts__alerts.map((alert: any) => alert.text)
    }
})
