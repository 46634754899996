import { Grid, makeStyles } from '@material-ui/core'
import { Wrapper } from 'common/components/molecules/Wrapper'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import React from 'react'
import { Card } from '../molecules/Card'
import { CardCtaProps } from '../molecules/CardCta'
import { Spacer } from '../molecules/Spacer'
import { Text } from '../molecules/Text'
import {
    Resources,
    ResourcesProps
} from 'common/components/organisms/Resources'

export interface ContactUsProps {
    layout: LayoutProps
    sectionHeading: string
    contactCards: {
        callToAction: CardCtaProps
        icon?: string
        title: string
        text: string
    }[]
    jumbotron: JumbotronProps
    resources: ResourcesProps
}

export const ContactUs: React.FC<ContactUsProps> = ({
    layout,
    contactCards = [],
    sectionHeading,
    jumbotron,
    resources
}) => {
    const useStyles = makeStyles({
        indicator: {
            backgroundColor: '#5261AC',
            height: 6,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5
        },
        tabPanel: {
            overflow: 'visible'
        },
        sectionHeading: {
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            paddingBottom: '25px',
            fontSize: '40px',
            color: '#3A3160'
        }
    })

    const classes = useStyles()

    return (
        <>
            <Layout {...layout}>
                <Jumbotron {...jumbotron} />
                <Spacer variant='xs' />
                <section>
                <Text
                    label={sectionHeading}
                    className={classes.sectionHeading}
                    variant='h4'
                />
                <Spacer variant='xs' />
                <Wrapper>
                    <Grid container spacing={6}>
                        {contactCards.map(card => (
                            <Grid item xs={12} md={4}>
                                <Card {...card} />
                            </Grid>
                        ))}
                    </Grid>
                    <Spacer />
                    <Resources {...resources} />
                </Wrapper>
                </section>
            </Layout>
        </>
    )
}
