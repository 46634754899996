import React, { useState } from 'react'
import { PhoneNumberInput } from '../inputs/PhoneNumberInput'
import { EmailInput } from '../inputs/EmailInput'
import { CurrencyInput } from '../inputs/CurrencyInput'
import { DefaultInput } from '../inputs/DefaultInput'
import { DatePicker } from '../inputs/DatePicker'
import { CalculatorInputType } from 'common/components/organisms/Calculator/utils/interfaces'
import { HelperText } from './HelperText'
export interface CalculatorStepInputProps {
    register?: () => {}
    placeholder?: string
    label?: string
    fieldName?: string
    inputtype?: string
    control?: any
    errors?: any
    helperText?: string
    errorMessage?: string
    defaultValue?: string
    formValues?: any
}

export const CalculatorStepInput: React.FC<CalculatorStepInputProps> = ({
    register,
    placeholder,
    label,
    fieldName,
    inputtype,
    control,
    errors,
    helperText,
    errorMessage,
    defaultValue,
    formValues,
}) => {
    const [isTouched, setIsTouched] = useState(false)
    const isInvalid = !!errors[fieldName ?? ''] && isTouched
    const childProps: CalculatorInputType = {
        register,
        placeholder,
        label,
        fieldName,
        inputtype,
        control,
        helperText,
        errorMessage,
        setIsTouched,
        isInvalid,
        defaultValue,
    }
    if (inputtype === 'phone') {
        return (
            <>
                <PhoneNumberInput {...childProps} />
                <HelperText
                    errors={errors}
                    fieldName={fieldName}
                    isTouched={isTouched}
                    helperText={helperText}
                />
            </>
        )
    }
    if (inputtype === 'email') {
        return (
            <>
                <EmailInput {...childProps} />
                <HelperText
                    errors={errors}
                    fieldName={fieldName}
                    isTouched={isTouched}
                    helperText={helperText}
                />
            </>
        )
    }
    if (inputtype === 'currency') {
        return (
            <>
                <CurrencyInput {...childProps} />
                <HelperText
                    errors={errors}
                    fieldName={fieldName}
                    isTouched={isTouched}
                    helperText={helperText}
                />
            </>
        )
    }
    if (inputtype === 'date') {
        return (
            <>
                <DatePicker {...childProps} />
                <HelperText
                    errors={errors}
                    fieldName={fieldName}
                    isTouched={isTouched}
                    helperText={helperText}
                />
            </>
        )
    }
    return (
        <>
            <DefaultInput {...childProps} />
            <HelperText
                errors={errors}
                fieldName={fieldName}
                isTouched={isTouched}
                helperText={helperText}
            />
        </>
    )
}
