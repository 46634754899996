import { Box } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import { Featured, FeaturedProps } from 'common/components/organisms/Featured'
import {
    Flatcards,
    FlatcardsProps
} from 'common/components/organisms/Flatcards'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    ProductList,
    ProductListProps
} from 'common/components/organisms/ProductList'
import {
    TemplateButton,
    TemplateButtonProps
} from 'common/components/organisms/TemplateButton'
import React from 'react'

export interface IntroductionToPensionsProps {
    benefits: BenefitsProps
    button: TemplateButtonProps
    featured: FeaturedProps
    flatcards: FlatcardsProps
    layout: LayoutProps
    productList: ProductListProps
    jumbotron: JumbotronProps
}

export const IntroductionToPensions: React.FC<IntroductionToPensionsProps> = ({
    benefits,
    button,
    featured,
    flatcards,
    jumbotron,
    layout,
    productList
}) => {
    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <Flatcards {...flatcards} />
            <Box>
                <ProductList {...productList} />
                <Wrapper>
                    <Featured {...featured} />
                </Wrapper>
            </Box>
            <Wrapper>
                <Spacer />
                <Benefits {...benefits} iconSize='lg' />
                <Spacer variant='xs' />
                <TemplateButton {...button} />
            </Wrapper>
        </Layout>
    )
}
