import { Box, Grid, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CardCtaContained } from 'common/components/molecules/CardCta'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface FeaturedProps {
    text: string
    title: string
    image?: string
    callToAction: {
        text: string
        icon?: string
        url: string
    }
}

export const Featured: React.FC<FeaturedProps> = ({
    text,
    title,
    image,
    callToAction,
}) => {
    const useStyles = makeStyles({
        image: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '28px',
            lineHeight: '132%',
            fontFeatureSettings: 'kern',
            color: '#5261AC',
        },
    })

    const classes = useStyles()
    const desktop = useDesktop()

    return (
        <section>
            <Spacer variant='md' />
            <Box
                data-testid='Featured'
                borderRadius={20}
                px={desktop ? 0 : 8}
                py={desktop ? 18 : 12}
                style={{
                    background:
                        // 'radial-gradient(63.27% 212.8% at 50% -41.37%, rgba(235, 237, 247, 0.4) 0%, rgba(235, 237, 247, 0) 100%), #F7F8FB'
                        '#fff',
                }}
            >
                <Grid container spacing={6}>
                    <Hidden smDown>
                        <Grid item xs={2} />
                    </Hidden>
                    <Grid item xs={12} md={3}>
                        {title && (
                            <Text
                                box={{ mb: desktop ? 0 : 13 }}
                                label={title}
                                variant={desktop ? 'h4' : 'h2'}
                                component='h1'
                            />
                        )}
                        {image && (
                            <img
                                alt='Icon'
                                className={classes.image}
                                src={`${image}?auto=format&w=600`}
                                srcSet={`
                                    ${image}?auto=format&w=150 150w,
                                    ${image}?auto=format&w=300 300w,
                                    ${image}?auto=format&w=600 600w,
                                    ${image}?auto=format&w=1200 1200w,
                                    ${image}?auto=format&w=2400 2400w,
                                `}
                                sizes='(max-width: 767px) 100vw, 50vw'
                            />
                        )}
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={1} />
                    </Hidden>
                    <Grid item xs={12} md={4}>
                        <Text
                            box={{ color: '#8983A0' }}
                            html
                            label={text}
                            component='p'
                        />
                        {callToAction.text && (
                            <CardCtaContained
                                {...callToAction}
                                label={callToAction?.text}
                            />
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Spacer variant='md' />
        </section>
    )
}
