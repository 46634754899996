

import React from 'react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Stack, HStack, Input, FormLabel, Box } from '@chakra-ui/react'
import { IconButton } from '@irishlife/ilgroupdesignsystem.atoms.icon-button'
import { PDFIcon } from '@irishlife/ilgroupdesignsystem.icons'
import { Tooltip } from '@irishlife/ilgroupdesignsystem.atoms.tooltip'

type PerformanceChartHeaderProps = UseChartHeaderProps & {
    showDateInputs?: boolean
    onPrint?: () => void
}

export function PerformanceChartHeader(props: PerformanceChartHeaderProps) {
    const { data, onChange, onPrint, isBarChart = false, showDateInputs = false } = props
    const { getButtonVariant, onZoom, toDateInputValue, fromDateInputValue, onDateFromChange, onDateToChange } = useChartHeader({ data, onChange, isBarChart })

    return (
        <>
            <Stack spacing={0} width='full' direction={['column', 'row']} alignItems='center' justifyContent='space-between' sx={{ '@media print': { flexDirection: 'row' } }}>
                <HStack spacing={0} w='full'>
                    <Text fontWeight='bold' fontSize='sm' pr={3}>Zoom</Text>
                    {zoomTypes.map((item) => {
                        const { id, label, value } = item
                        return (
                            <Button
                                width={value === "10 years max" ? 'auto' : 9}
                                key={id}
                                rounded='sm'
                                size='sm'
                                fontSize="sm"
                                variant={getButtonVariant(value as ZoomVariant)}
                                px={2}
                                name={value}
                                onClick={() => onZoom(item)}
                            >
                                {label}
                            </Button>
                        )
                    })}
                </HStack>
                {showDateInputs &&
                    <HStack spacing={0} width={['full', 'auto']} alignItems='end' sx={{ '@media print': { width: 'auto' } }}>
                        <Stack spacing={0} direction={['column', 'row']} alignItems={["start", 'center']} sx={{ '@media print': { flexDir: 'row', alignItems: 'center' } }}>
                            <FormLabel m={0} fontWeight='semibold' color='vibrant.500' as='label' fontSize='sm' htmlFor='from'>From</FormLabel>
                            <Box pl={[0, 2]} sx={{ '@media print': { pl: 2 } }}>
                                <Input id='from' type="date" size='sm' rounded='sm' value={fromDateInputValue} onChange={onDateFromChange} />
                            </Box>
                        </Stack>
                        <Stack pl={4} spacing={0} direction={['column', 'row']} alignItems={["start", 'center']} sx={{ '@media print': { flexDir: 'row', alignItems: 'center' } }}>
                            <FormLabel m={0} fontWeight='semibold' color='vibrant.500' as='label' fontSize='sm' htmlFor='to'>To</FormLabel>
                            <Box pl={[0, 2]} sx={{ '@media print': { pl: 2 } }}>
                                <Input id='to' type="date" size='sm' rounded='sm' value={toDateInputValue} onChange={onDateToChange} />
                            </Box>
                        </Stack>
                        <Box pl={1} sx={{ '@media print': { display: 'none' } }}>
                            <Tooltip label='Print Chart' placement='top' variant='dark'>
                                <IconButton variant='ghost' size='sm' aria-label='download chart' icon={<PDFIcon fontSize={24} />} id='print-icon' onClick={onPrint} />
                            </Tooltip>
                        </Box>
                    </HStack>
                }
            </Stack>
            <style>
                {`
                @media print {
                    @page {
                        size: A4;
                        margin: 1cm;
                    }
                    html, body {
                        page-break-after: avoid;
                        page-break-before: avoid;
                    }
                }
                `}
            </style>
        </>
    )
}

type ZoomVariant = "1m" | "3m" | "6m" | "ytd" | '1y' | '10 years max'
const zoomTypeNone = { id: 999, label: '', value: '', monthAgo: 999 }

type UseChartHeaderProps = {
    data: Record<string, string | number>[]
    onChange: Function
    isBarChart?: boolean
}

function useChartHeader(props: UseChartHeaderProps) {
    const { data, onChange, isBarChart = false } = props
    const [zoomType, setZoomType] = React.useState(zoomTypes[5])
    const [fromTimestamp, setStartTimestamp] = React.useState(() => subtractMonthsFromDate(new Date(), zoomType.monthAgo))
    const [toTimestamp, setEndTimestamp] = React.useState(() => new Date().getTime())

    const onDateFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartTimestamp(new Date(e.target.value || new Date()).getTime())
        setZoomType(zoomTypeNone)
    }

    const onDateToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndTimestamp(new Date(e.target.value || new Date()).getTime())
        setZoomType(zoomTypeNone)
    }

    const onZoom = React.useCallback((item) => {
        setZoomType(item)
        if (item.value === "ytd") {
            setStartTimestamp(getFirstDayOfCurrentYear())
        } else {
            setStartTimestamp(subtractMonthsFromDate(new Date(), item.monthAgo))
        }
    }, [])

    const getButtonVariant = React.useCallback((type: ZoomVariant) => {
        return zoomType.value === type ? 'primary' : 'ghost'
    }, [zoomType])


    React.useEffect(() => {
        if (data) {
            const newData = getHistoricalData({ data, fromTimestamp, toTimestamp, isBarChart })
            onChange(newData)
        }
    }, [data, zoomType, onChange, fromTimestamp, toTimestamp, isBarChart])

    return {
        onDateFromChange,
        onDateToChange,
        onZoom,
        getButtonVariant,
        fromDateInputValue: formatDateValue(fromTimestamp),
        toDateInputValue: formatDateValue(toTimestamp),
    }
}

function getFirstDayOfCurrentYear() {
    const date = new Date()
    date.setMonth(0)
    date.setDate(1)
    return date.getTime()
}

function subtractMonthsFromDate(date: Date, months: number) {
    date.setMonth(date.getMonth() - months)
    return date.getTime()
}

function formatDateValue(timestamp: number) {
    const date = new Date(timestamp)
    return date.toJSON().slice(0, 10);
}

type GetHistoricalDataProps = {
    data: Record<string, string | number>[],
    fromTimestamp: number,
    toTimestamp: number,
    isBarChart: boolean
}

function getHistoricalData(props: GetHistoricalDataProps) {
    const { data, fromTimestamp, toTimestamp, isBarChart } = props
    if (isBarChart) {
        const fromYear = new Date(fromTimestamp).getFullYear()
        const toYear = new Date(toTimestamp).getFullYear()
        return data.filter(({ year }) => year >= fromYear && year <= toYear)
    }
    return data.filter(({ timestamp }) => timestamp >= fromTimestamp && timestamp <= toTimestamp)
}

type Zoom = { id: number, label: string, value: string, monthAgo: number }
const zoomTypes: Zoom[] = [
    { id: 1, label: '1m', value: '1m', monthAgo: 1 },
    { id: 2, label: '3m', value: '3m', monthAgo: 3 },
    { id: 3, label: '6m', value: '6m', monthAgo: 6 },
    { id: 4, label: 'YTD', value: 'ytd', monthAgo: 0 },
    { id: 5, label: '1y', value: '1y', monthAgo: 12 },
    { id: 6, label: '10 years max', value: '10 years max', monthAgo: 120 },
]
