import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { MapsQuarterly } from 'common/components/templates/MapsQuarterly'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface MapsQuarterlyUpdatesPageProps {
    pageContext: any
}

export const MapsQuarterlyUpdatesPage: React.FC<MapsQuarterlyUpdatesPageProps> = ({
    pageContext
}) => {
    return <MapsQuarterly {...getMapsQuarterlyUpdatesData(pageContext)} />
}

export default MapsQuarterlyUpdatesPage

export const getMapsQuarterlyUpdatesData = (data: any) => ({
    layout: getLayoutData(data),
    jumbotron: getJumbotronData(data),
    productList: {
        heading: data.productlist__heading,
        text: data.productlist__summary,
        title: data.title,

        products: (data.products as Array<any>).map((product: any) => ({
            icon: product.icon,
            title: product.title,
            text: product.text,
            callToAction: product.call_to_action
        }))
    }
})
