import { Router as ReachRouter } from '@reach/router'
import { ScrollToTop } from 'common/components/atoms/ScrollToTop'
import { useBlogPosts } from 'common/hooks/useBlogPosts'
import { useSiteData } from 'common/hooks/useSiteData'
import { HomePage } from 'common/pages/HomePage'
import { getLatestBlogPosts } from 'common/utils/getBlogPosts'
import { getFooterData } from 'common/utils/getFooterData'
import { getLogin } from 'common/utils/getLogin'
import { getNavigation } from 'common/utils/getNavigation'
import { getPages } from 'common/utils/getPages'
import { getSiteSearchData } from 'common/utils/getSiteSearchData'
import React from 'react'

export interface IRouterReachProps {}

export const Router: React.FC<IRouterReachProps> = (props) => {
    const siteData: any = useSiteData()
    const blogPosts: any = useBlogPosts()

    if (!siteData || !blogPosts) return null
    const navigation = getNavigation(siteData)
    const login = getLogin(siteData)
    const footer = getFooterData(siteData, '/', siteData.footer__variant)
    const pages = getPages({
        blogPosts,
        navigationItems: siteData.navigation_items,
        siteData,
    })

    const siteSearchData = getSiteSearchData(pages)

    const { navigation_items, ...rest } = siteData
    const flatcardsData = navigation_items
        ?.filter(
            (productLine: any) =>
                productLine.type === 'product_line' &&
                productLine.codename !== 'your_goals' &&
                productLine.codename !== 'resources'
        )
        .map((productLine: any) => ({
            buttonProps: {
                variant: 'contained', //need to change text value in cms
                url: productLine.card_cta_0396ba7__cta_url,
                label: productLine.card_cta_0396ba7__cta_label,
            },
            icon: productLine.productlist__icon,
            text: productLine.productlist__summary,
            title: productLine.title,
        }))

    return (
        <ReachRouter>
            <ScrollToTop path='/'>
                <HomePage
                    pageContext={{
                        ...rest,
                        flatcardsData,
                        layout: { footer, navigation, login, siteSearchData },
                        blogPosts: getLatestBlogPosts(blogPosts),
                    }}
                    path='/'
                />

                {pages.map((page) => {
                    try {
                        const Page =
                            require(`../../../common/pages/${page.template}.tsx`).default
                        return (
                            <Page
                                key={page.path}
                                pageContext={page.context}
                                path={page.path}
                            />
                        )
                    } catch (error) {
                        console.log(`${page.template}.tsx not found in pages`)

                        return null
                    }
                })}
                {pages.map((page) => {
                    try {
                        const Page =
                            require(`../../../common/pages/${page.template}.tsx`).default
                        return (
                            <Page
                                key={page.path}
                                pageContext={page.context}
                                path={`/preview/${page.context.codename}`}
                            />
                        )
                    } catch (error) {
                        console.log(`${page.template}.tsx not found in pages`)

                        return null
                    }
                })}
            </ScrollToTop>
        </ReachRouter>
    )
}
