import { Box } from '@material-ui/core'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import React from 'react'
import { Spacer } from '../molecules/Spacer'
import { Contact, ContactProps } from '../organisms/Contact'
import { FeatureList, FeatureListProps } from '../organisms/FeatureList'
import { Jumbotron, JumbotronProps } from '../organisms/Jumbotron'

export interface FinancialAdviceProps {
    layout: LayoutProps
    jumbotron: JumbotronProps
    contact: ContactProps
    featureList: FeatureListProps
}

export const FinancialAdvice: React.FC<FinancialAdviceProps> = ({
    layout,
    jumbotron,
    featureList,
    contact
}) => {
    return (
        <Layout {...layout}>
            {jumbotron.title && <Jumbotron {...jumbotron} />}
            <Box bgcolor='#f7f8fb'>
                <Wrapper>
                    <Spacer />
                    {featureList.title && <FeatureList {...featureList} />}
                    <Box bgcolor='#f7f8fb'>
                        <Spacer />
                        <Contact {...contact} />
                        <Spacer />
                    </Box>
                </Wrapper>
            </Box>
        </Layout>
    )
}
