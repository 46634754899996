import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { WorkLife } from 'common/components/templates/WorkLife'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'
export interface WorkLifePageProps {
    pageContext: any
}

export const WorklifePage: React.FC<WorkLifePageProps> = ({
    pageContext
}) => {
    return <WorkLife {...getWorkLifeData(pageContext)} />
}

export default WorklifePage
export const getWorkLifeData = (data: any) => ({
    layout: getLayoutData({ ...data }),
    jumbotron: {
        ...getJumbotronData(data)
    },
    timeline: {
        text: data.text,
        title: data.title,
        heading: data.heading,
        steps: data.timeline__steps
    },
    features: data.features_list.map((item: any) => ({
        image: item.image,
        title: item.title,
        features: item.features,
        variant: item.variant,
        callToAction: {
            analyticsId: item.call_to_action__analytics_id,
            label: item.call_to_action__label,
            icon: item.call_to_action__icon,
            variant: item.call_to_action__variant,
            [`${item.call_to_action__iconlocation}Icon`]: item.call_to_action__icon,
            url: item.call_to_action__url
        },
        cta: item.show_cta
    })),
    campaignFeature: {
        text: data.campaign_feature[0].subtitle,
        title: data.campaign_feature[0].title,
        image: data.campaign_feature[0].image,
        callToAction: {
            analyticsId: data.campaign_feature[0].call_to_action__analytics_id,
            label: data.campaign_feature[0].call_to_action__label,
            icon: data.campaign_feature[0].call_to_action_icon,
            url: data.campaign_feature[0].call_to_action__url,
            [`${data.call_to_action__iconlocation}Icon`]: data.call_to_action__icon,
            variant: data.campaign_feature[0].call_to_action__variant
        }
    }
})