import { JumbotronProps } from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    ProductList,
    ProductListProps
} from 'common/components/organisms/ProductList'
import React from 'react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Container } from '@chakra-ui/react'
export interface MapsQuarterlyProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
    productList: ProductListProps
}

export const MapsQuarterly: React.FC<MapsQuarterlyProps> = ({
    jumbotron,
    layout,
    productList
}) => {
    return (
        <Layout {...layout} box={{ bgcolor: "white" }}>
            <Container maxW='6xl' py={16}>
                <Text variant="title-2xl">{jumbotron.title}</Text>
            </Container>
            <Container maxW='6xl'>
                <ProductList {...productList} />
            </Container>
        </Layout>
    )
}
