import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import React from 'react'
import { DownloadFeature, DownloadFeatureProps } from '../organisms/DownloadFeature'
import { ProductList, ProductListProps } from '../organisms/ProductList'
import { JumbotronProps } from 'common/components/organisms/Jumbotron'
import { Box, Container, Stack } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'

export interface CardListProps {
    productList: ProductListProps
    extraDownloads: DownloadFeatureProps
    jumbotron: JumbotronProps
    layout: LayoutProps
}

export const CardList: React.FC<CardListProps> = ({
    productList,
    extraDownloads,
    jumbotron,
    layout
}) => {
    return (
        <Layout {...layout}>
            <Box bg='white'>
                <Container maxW='6xl' py={[10, 16]}>
                    <Stack spacing={4}>
                        <Text variant="title-2xl">{jumbotron.title}</Text>
                        <Text variant="title-md">{jumbotron.subtitle}</Text>
                    </Stack>
                </Container>
            </Box>
            <Container maxW='6xl' px={0}>
                <DownloadFeature features={extraDownloads.features} />
            </Container>
            <Container maxW='6xl'>
                <ProductList
                    products={productList.products}
                    text=''
                    title={productList.title}
                    heading=''
                    filter={true}
                />
            </Container>
        </Layout>
    )
}
