import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    Resources,
    ResourcesProps
} from 'common/components/organisms/Resources'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface ProductOneplanProps {
    benefits: BenefitsProps
    jumbotron: JumbotronProps
    layout: LayoutProps
    resources: ResourcesProps
}

export const ProductOneplan: React.FC<ProductOneplanProps> = ({
    benefits,
    jumbotron,
    resources,
    layout
}) => {
    const desktop = useDesktop()
    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <Wrapper>
                <Spacer variant='md' />
                <Benefits {...benefits} />
                <Spacer variant={desktop ? 'lg' : 'md'} />
                {resources.title && (
                    <>
                        <Resources {...resources} />
                    </>
                )}
            </Wrapper>
        </Layout>
    )
}
