import {
    Box,
    HStack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Checkbox } from '@irishlife/ilgroupdesignsystem.atoms.checkbox'
import { IconButton } from '@irishlife/ilgroupdesignsystem.atoms.icon-button'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Tooltip } from '@irishlife/ilgroupdesignsystem.atoms.tooltip'
import {
    ChevronDownIcon,
    ChevronUpIcon,
    DocumentDownloadIcon,
} from '@irishlife/ilgroupdesignsystem.icons'
import { Link } from '@reach/router'
import { useFundCentreUrls } from 'common/hooks/useFundCentreUrls'
import React from 'react'

const months = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
}

const ratingColourMap: Record<string, string> = {
    1: '#222E65',
    2: '#5158A2',
    3: '#009ED1',
    4: '#C9D52C',
    5: '#F8B613',
    6: '#E45A25',
    7: '#AB1F2C',
}

const headers = [
    { label: 'fund name', key: 'FundName' },
    { label: 'risk', key: 'FundRiskRating' },
    { label: 'SFDR category', key: 'SfdrArticleRating' },
    { label: 'launch date', key: 'FundStartDate' },
    { label: 'select', key: 'SelectedFund' },
    { label: '1 month', key: 'OneMonthGrowthPercent' },
    { label: '6 months', key: 'SixMonthGrowthPercent' },
    { label: '1 year', key: 'OneYearGrowthPercent' },
    { label: '3 years', key: 'ThreeYearGrowthPercent' },
    { label: '5 years', key: 'FiveYearGrowthPercent' },
    { label: '10 years', key: 'TenYearGrowthPercent' },
    { label: 'performance to', key: 'SinceLaunchPercent' },
]

const styles = {
    article8: {
        width: 0,
        height: 0,
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: '10px solid limegreen',
        position: 'relative',
        verticalAlign: 'top',
        marginRight: '5px',
    },
    article9: {
        width: 0,
        height: 0,
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: '10px solid darkgreen',
        position: 'relative',
        verticalAlign: 'top',
        marginRight: '5px',
    },
}

export function FundsTable(props: FundsTableProps) {
    const { data, defaultSelected = [], onSelect } = props
    const [selectedFunds, setSelectedFunds] = React.useState(defaultSelected)
    const [tableDataSorted, setTableDataSorted] = React.useState<Fund[]>([])
    const [sortBy, setSortBy] = React.useState<SortBy>({
        type: 'desc',
        key: 'FundName',
    })

    React.useEffect(() => {
        onSelect(selectedFunds)
    }, [onSelect, selectedFunds])

    let isSortInitiated = React.useRef(false)

    const multiAssetFunds = React.useMemo(() => {
        if (!data || isSortInitiated.current) return []
        return getMultiAssetFunds(data)
    }, [data, isSortInitiated])

    const handleSortFunds = React.useCallback(
        ({ key, isNumeric }) => {
            const newSortBy = getNewSortByProps(sortBy, key, isNumeric)
            if (!data || newSortBy.key === 'SelectedFund') return
            // we want to show the multiAssetFunds at the top only on the first render
            const showMultiAssetPortfolioFundsAtTop = !isSortInitiated.current
            let priorityFunds = showMultiAssetPortfolioFundsAtTop
                ? multiAssetFunds
                : []
            let result = data
            if (showMultiAssetPortfolioFundsAtTop) {
                // filter out multiAssetFunds
                result = data.filter(
                    ({ FundName }) =>
                        !priorityFunds.some((f) => f.FundName === FundName)
                )
            }
            result = getSortedFunds({ data: result, sortBy: newSortBy })
            setTableDataSorted([...priorityFunds, ...result])
            setSortBy(newSortBy)
        },
        [data, multiAssetFunds, sortBy]
    )

    const handleSortSelectedFunds = React.useCallback(() => {
        if (
            selectedFunds.length === 0 ||
            selectedFunds.length === tableDataSorted.length
        )
            return
        const newSortBy = getNewSortByProps(sortBy, 'SelectedFund')
        const sortedSelected = tableDataSorted
            .slice()
            .sort(sortSelectedFunds({ ...newSortBy, selectedFunds }))
        setTableDataSorted(sortedSelected)
        setSortBy(newSortBy)
    }, [sortBy, selectedFunds, tableDataSorted])

    React.useMemo(() => {
        if (data) handleSortFunds({ type: 'desc', key: 'FundName' })
        // run only once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const onTableHeadClick = React.useCallback(
        (headIndex: number) => {
            isSortInitiated.current = true
            const sortHandlers: Record<number, Function> = {
                0: () => handleSortFunds({ key: 'FundName' }),
                1: () =>
                    handleSortFunds({ key: 'FundRiskRating', isNumeric: true }),
                2: () => handleSortFunds({ key: 'FundStartDate' }),
                3: () => handleSortSelectedFunds(),
            }
            const handler = sortHandlers[headIndex]
            if (handler) handler()
        },
        [handleSortFunds, handleSortSelectedFunds]
    )

    const onSelectFund = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        const isChecked = selectedFunds.includes(value)
        if (isChecked) {
            setSelectedFunds((prev) => prev.filter((item) => item !== value))
        } else {
            setSelectedFunds((prev) => [...prev, value])
        }
    }

    return (
        <Box
            overflowX='auto'
            position='relative'
            sx={{ '@media print': { top: 0, left: 0 } }}
        >
            <TableHeader />
            <TableContainer
                sx={{
                    '@media print': {
                        maxH: 'auto',
                        height: 'auto',
                        minW: 'auto',
                    },
                }}
                maxH='75vh'
                minW='6xl'
                w='full'
                overflowX='auto'
                overflowY='scroll'
                borderWidth='1px'
                borderColor='#D9D5EC'
            >
                <Table
                    sx={{ '@media print': { td: { fontSize: 10 } } }}
                    variant='simple'
                    position='relative'
                    border-collapse='collapse'
                    w='full'
                    size='sm'
                >
                    <FundsTableHead
                        sortBy={sortBy}
                        onTableHeadClick={onTableHeadClick}
                    />
                    <FundsTableBody
                        tableData={tableDataSorted}
                        onSelect={onSelectFund}
                        selectedFunds={selectedFunds}
                    />
                </Table>
            </TableContainer>
        </Box>
    )
}

function getMultiAssetFunds(data: Fund[]) {
    return data.filter(({ FundName }) =>
        (FundName as string).includes('Multi Asset Portfolio Fund')
    )
}

type FundsTableProps = {
    data: Record<string, string | number>[]
    defaultSelected: string[]
    onSelect: (values: string[]) => void
    children?: React.ReactNode
}

function getNewSortByProps(
    prevSortBy: SortBy,
    key: SortKey,
    isNumeric = false
) {
    const hasBeenSorted = prevSortBy.key === key
    const newType = hasBeenSorted && prevSortBy.type === 'asc' ? 'desc' : 'asc'
    return { key, isNumeric, type: newType } as SortBy
}

function TableHeader() {
    return (
        <HStack
            justifyContent='flex-end'
            w='6xl'
            position='absolute'
            zIndex={20}
            sx={{ '@media print': { display: 'none' } }}
        >
            <Box mb={-14} mr={56}>
                <Text
                    color='#6E6893'
                    textTransform='uppercase'
                    variant='body-sm'
                    fontWeight='bold'
                >
                    Fund Performance - Cumulative
                </Text>
            </Box>
        </HStack>
    )
}

type FundsTableHeadProps = {
    sortBy: SortBy
    onTableHeadClick: (index: number) => void
}

function FundsTableHead(props: FundsTableHeadProps) {
    const { sortBy, onTableHeadClick } = props
    return (
        <Thead>
            <Tr p={0}>
                {headers.map(({ label, key }, index) => {
                    const shouldShowArrow = index <= 3
                    const isDesc = sortBy.type === 'desc' && sortBy.key === key
                    return (
                        <Th
                            key={key}
                            p={0}
                            position='sticky'
                            top={0}
                            bg='white'
                            zIndex={10}
                            shadow='base'
                            sx={{
                                '@media print': {
                                    display:
                                        label === 'select'
                                            ? 'none'
                                            : 'table-cell',
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <Box
                                pt={10}
                                px='1'
                                w='full'
                                bg={shouldShowArrow ? 'white' : '#F4F2FF'}
                                borderBottomWidth='1px'
                                borderBottomColor='#D9D5EC'
                                sx={{ '@media print': { pt: 0 } }}
                            >
                                <Button
                                    sx={{ '@media print': { fontSize: 10 } }}
                                    onClick={() => onTableHeadClick(index)}
                                    bg='inherit'
                                    whiteSpace='normal'
                                    color='#6E6893'
                                    _hover={{}}
                                    _active={{}}
                                    width='full'
                                    textTransform='uppercase'
                                    rounded='none'
                                    display='flex'
                                    justifyContent={
                                        index >= 2 ? 'center' : 'start'
                                    }
                                    px={3}
                                    // px={0}
                                    fontSize={12}
                                    fontWeight='bold'
                                    _focus={{
                                        shadow: shouldShowArrow
                                            ? 'outline'
                                            : 'none',
                                    }}
                                    // @ts-ignore
                                    rightIcon={
                                        shouldShowArrow &&
                                        (isDesc ? (
                                            <ChevronUpIcon
                                                fontSize={12}
                                                color='brand.900'
                                                sx={{
                                                    '@media print': {
                                                        display: 'none',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <ChevronDownIcon
                                                fontSize={12}
                                                mt='2px'
                                                color='brand.900'
                                                sx={{
                                                    '@media print': {
                                                        display: 'none',
                                                    },
                                                }}
                                            />
                                        ))
                                    }
                                >
                                    {label}
                                </Button>
                            </Box>
                        </Th>
                    )
                })}
            </Tr>
        </Thead>
    )
}

type FundsTableBodyProps = {
    tableData: Fund[]
    onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
    selectedFunds: string[]
}

function FundsTableBody(props: FundsTableBodyProps) {
    const { tableData, onSelect, selectedFunds } = props
    return (
        <Tbody>
            {tableData.map((item: Fund) => {
                const isChecked = selectedFunds.includes(`${item.FundId}`)
                return (
                    <TableBodyRowMemoized
                        key={item.FundId}
                        onSelect={onSelect}
                        isSelected={isChecked}
                        fund={item}
                    />
                )
            })}
        </Tbody>
    )
}

type TableBodyRowProps = {
    isSelected: boolean
    onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
    fund: Fund
}

function TableBodyRow(props: TableBodyRowProps) {
    const { isSelected, onSelect, fund } = props
    const {
        FundFactsheet,
        FundName,
        FundRiskRating,
        SfdrArticleRating,
        FundId,
        FundStartDate,
        OneMonthGrowthPercent,
        SixMonthGrowthPercent,
        OneYearGrowthPercent,
        ThreeYearGrowthPercent,
        FiveYearGrowthPercent,
        TenYearGrowthPercent,
        PerformanceTo,
    } = fund

    const { fundDetailUrl } = useFundCentreUrls()

    const downloadFactSheet = React.useCallback(() => {
        window.open(FundFactsheet as string, '_blank')
    }, [FundFactsheet])

    return (
        <Tr>
            <Td px={3} width={44} sx={{ '@media print': { width: 20 } }}>
                <HStack
                    alignItems='center'
                    justifyContent='space-between'
                    spacing={0}
                >
                    <Link
                        style={{ fontWeight: 500, whiteSpace: 'normal' }}
                        to={`${fundDetailUrl}?id=${FundId}`}
                    >
                        <Tooltip
                            label='See Fund Price'
                            variant='dark'
                            placement='top-start'
                        >
                            <Box
                                width={44}
                                sx={{ '@media print': { width: 20 } }}
                                color='gray.800'
                                display={'flex'}
                                maxWidth={'35ch'}
                            >
                                {FundName}
                            </Box>
                        </Tooltip>
                    </Link>
                    <Box sx={{ '@media print': { display: 'none' } }}>
                        <Tooltip
                            label='View Factsheet'
                            variant='dark'
                            placement='top'
                        >
                            <IconButton
                                onClick={downloadFactSheet}
                                size='sm'
                                variant='unstyled'
                                aria-label='download fund'
                                icon={
                                    <DocumentDownloadIcon
                                        fontSize={22}
                                        color='vibrant.500'
                                    />
                                }
                            />
                        </Tooltip>
                    </Box>
                </HStack>
            </Td>
            <Td px={3} textAlign='center'>
                <Box
                    py={1}
                    borderColor={ratingColourMap[FundRiskRating]}
                    borderWidth='1px'
                    rounded='md'
                >
                    IL{FundRiskRating}
                </Box>
            </Td>
            <Td>
                {' '}
                <Box display={'flex'} justifyContent={'center'}>
                    <Tooltip
                        label={
                            SfdrArticleRating === '8'
                                ? 'Article 8 fund'
                                : SfdrArticleRating === '9'
                                ? 'Article 9 fund'
                                : ''
                        }
                        variant='dark'
                        placement='top-start'
                    >
                        {SfdrArticleRating === '8' ? (
                            <Box sx={styles.article8}></Box>
                        ) : SfdrArticleRating === '9' ? (
                            <Box sx={styles.article9}></Box>
                        ) : (
                            ''
                        )}
                    </Tooltip>
                </Box>
            </Td>
            <Td px={3} textAlign='center'>
                {formatDate(FundStartDate as string)}
            </Td>
            <Td
                px={3}
                textAlign='center'
                sx={{
                    '@media print': { display: 'none', visibility: 'hidden' },
                }}
            >
                <Box display='flex' justifyContent='center' alignItems='center'>
                    <Checkbox
                        isChecked={isSelected}
                        value={FundId}
                        onChange={onSelect}
                    />
                </Box>
            </Td>
            <Td px={3} textAlign='center'>
                {formatRowValue(OneMonthGrowthPercent as string)}
            </Td>
            <Td px={3} textAlign='center'>
                {formatRowValue(SixMonthGrowthPercent as string)}
            </Td>
            <Td px={3} textAlign='center'>
                {formatRowValue(OneYearGrowthPercent as string)}
            </Td>
            <Td px={3} textAlign='center'>
                {formatRowValue(ThreeYearGrowthPercent as string)}
            </Td>
            <Td px={3} textAlign='center'>
                {formatRowValue(FiveYearGrowthPercent as string)}
            </Td>
            <Td px={3} textAlign='center'>
                {formatRowValue(TenYearGrowthPercent as string)}
            </Td>
            <Td px={3} textAlign='center'>
                {PerformanceTo}
            </Td>
        </Tr>
    )
}

// @ts-ignore
function rowPropsAreEqual(prev, next) {
    return prev.isSelected === next.isSelected
}

const TableBodyRowMemoized = React.memo(TableBodyRow, rowPropsAreEqual)

type Month = keyof typeof months
function sortFundsByDate({ type, key }: SortBy) {
    return (a: Fund, b: Fund) => {
        const [Aday, Amonth, Ayear] = (a[key] as string).split(' ')
        const A = new Date(+Ayear, months[Amonth as Month], +Aday).getTime()
        const [Bday, Bmonth, Byear] = (b[key] as string).split(' ')
        const B = new Date(+Byear, months[Bmonth as Month], +Bday).getTime()
        if (type === 'asc') return A - B
        return B - A
    }
}

type Fund = Record<string, string | number>

function sortSelectedFunds({
    type,
    selectedFunds,
}: SortBy & { selectedFunds: string[] }) {
    return (a: Fund, b: Fund) => {
        const A = a['FundId'] as string
        const B = b['FundId'] as string
        if (type === 'asc') {
            if (selectedFunds.includes(A)) return -1
            if (selectedFunds.includes(B)) return 1
            return 0
        }
        if (type === 'desc') {
            if (selectedFunds.includes(A)) return 1
            if (selectedFunds.includes(B)) return -1
            return 0
        }
        return 0
    }
}

function formatDate(date: string) {
    const [dd, mm, yyyy] = date.split(' ')
    return `${dd} ${mm} ${yyyy}`
}

type GetSortedFunds = {
    data: Fund[]
    sortBy: SortBy
}

function getSortedFunds(props: GetSortedFunds) {
    const { data, sortBy } = props
    const sortFundsFnMap: Record<string, Function> = {
        FundName: () => [...data].sort(sortFunds(sortBy)),
        FundRiskRating: () => [...data].sort(sortFunds(sortBy)),
        FundStartDate: () => [...data].sort(sortFundsByDate(sortBy)),
    }
    return sortFundsFnMap[sortBy.key]()
}

function formatRowValue(value: string) {
    return value === 'N/A' ? '-' : value + '%'
}

type SortKey = 'FundName' | 'FundRiskRating' | 'FundStartDate' | 'SelectedFund'
type SortBy = {
    type: 'desc' | 'asc'
    key: SortKey
    isNumeric?: boolean
}

function sortFunds({ type, key, isNumeric }: SortBy) {
    return (a: Fund, b: Fund) => {
        const A = isNumeric ? +a[key] : a[key]
        const B = isNumeric ? +b[key] : b[key]
        if (type === 'asc') {
            if (A < B) return -1
            if (B < A) return 1
            return 0
        }
        if (type === 'desc') {
            if (A > B) return -1
            if (B > A) return 1
            return 0
        }
        return 0
    }
}
