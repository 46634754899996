import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { CampaignFeatureProps } from 'common/components/organisms/CampaignFeature'
import {
    CampaignJumbotron,
    CampaignJumbotronProps
} from 'common/components/organisms/CampaignJumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    TimelineSteps,
    TimelineStepsProps
} from 'common/components/organisms/TimelineSteps'
import {
    FeatureList,
    FeatureListProps
} from 'common/components/organisms/FeatureList'
import React from 'react'
import { CampaignFeature } from '@irishlife/ilgroupdesignsystem.organisms.campaign-feature'
import { getCampaignFeatureProps } from 'common/utils/geCampaignFeatureProps'

export interface WorkLifeProps {
    layout: LayoutProps
    jumbotron: CampaignJumbotronProps
    timeline: TimelineStepsProps
    features: FeatureListProps[]
    campaignFeature: CampaignFeatureProps
}

export const WorkLife: React.FC<WorkLifeProps> = ({
    layout,
    jumbotron,
    timeline,
    features,
    campaignFeature
}) => {

    const campaignFeatureProps = getCampaignFeatureProps(campaignFeature)

    return (
        <Layout {...layout}>
            <CampaignJumbotron {...jumbotron} />
            <CampaignFeature {...campaignFeatureProps} />
            <Wrapper style={{ background: '#fff' }}>
                {timeline.title && <TimelineSteps {...timeline} />}
            </Wrapper>
            <Spacer />
            <Wrapper style={{ background: 'transparent' }}>
                {features && features.map((feature: FeatureListProps) => (
                    <>
                        <FeatureList {...feature} />
                        <Spacer />
                    </>
                ))}
            </Wrapper>
        </Layout>
    )
}
